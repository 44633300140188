import React from 'react';
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import './footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="footer-row">
                    <a href="#" target="_blank" rel="noopener noreferrer" ><i class="fa fa-facebook"></i></a>
                    <a href="#" target="_blank" rel="noopener noreferrer" ><i class="fa fa-instagram"></i></a>
                    <a href="#" target="_blank" rel="noopener noreferrer" ><i class="fa fa-youtube"></i></a>
                    <a href="#" target="_blank" rel="noopener noreferrer" ><i class="fa fa-twitter"></i></a>
                </div>

                <div className="footer-row">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/">Services</Link></li>
                        <li><Link to="/">Shop</Link></li>
                        <li><Link to="/">Book</Link></li>
                    </ul>
                </div>

                <div className="footer-row">
                    S&J Barbershop Copyright © 2024 S&J - All rights reserved || Designed By: ELKANA
                </div>
            </div>
        </footer>
    )
}

export default Footer